import { apiGetAuth, apiPostAuth, apiPutAuth } from '@/utils/api'; 
import { API_MASTER, API_AUTH } from '@/utils/api-url'; 

const state = { 
  po_events: [], 
  po_event: null, 
  total: 0, 
  loading: false, 
  error: null 
}; 

const mutations = { 
  SET_PO_EVENTS(state, po_events) { 
    state.po_events = po_events; 
  }, 
  SET_PO_EVENT(state, po_event) { 
    state.po_event = po_event; 
  }, 
  SET_TOTAL(state, total) { 
    state.total = total; 
  }, 
  SET_LOADING(state, isLoading) { 
    state.loading = isLoading; 
  }, 
  SET_ERROR(state, error) { 
    state.error = error; 
  } 
}; 

const actions = { 
  async getPoEvent({ commit }, { limit, page, search }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.PO_EVENT}?limit=${limit}&page=${page}&search=${search}`);       
      if (response.data.status_code === 200) { 
        
        commit('SET_PO_EVENTS', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        console.log('error');
        
        commit('SET_PO_EVENTS', []);
        // throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async createPoEvent({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPostAuth(API_MASTER.CREATE_PO_EVENT, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async updatePoEvent({ commit }, payload) { 
    commit('SET_LOADING', true); 
    console.log(payload);
    
    try { 
      const response = await apiPutAuth(API_MASTER.UPDATE_PO_EVENT, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async getPoEventById({ commit }, po_number) { 
    commit('SET_LOADING', true); 
    
    try { 
      const response = await apiGetAuth(`${API_MASTER.DETAIL_PO_EVENT}?po_number=${po_number}`);
      if (response.data.status_code === 200) { 
        return response.data.data 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getEvent({ commit }, search) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.EVENT}?limit=100&page=1&type=po_event&search=${search}`); 
      if (response.data.status_code === 200) {
        return response.data.data.data.map(event => ({
          id: event.id,
          value: event.event_number,
          name: event.event_number // Adjusted to use module_name from response
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getEventById({ commit }, id ) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.EVENT}/${id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getBranches({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.BRANCHES}`); 
      if (response.data.status_code === 200) {
        // Return the mapped branches data
        return response.data.data.map(branches => ({
          id: branches.id,
          value: branches.id,
          name: branches.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getOutlet({ commit }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.BR_OUTLET}?limit=1000&page=1`); 
      if (response.data.status_code === 200) { 
        commit('SET_BR_OUTLETS', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getTaxByCodes({ commit }, type) {
    commit('SET_LOADING', true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.TAX}?type=${type}`);
      if (response.data.status_code === 200) {
        // Return the tax codes data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getItems({ commit }) {
    commit('SET_LOADING', true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.ITEM}?limit=100&page=1`);
      if (response.data.status_code === 200) {
        return response.data.data.data.map(item => ({
          id: item.item_code,
          value: item.item_code,
          name: item.item_name,
          percentage_badan : item.percentage_badan,
          percentage_perorangan : item.percentage_perorangan,
          pph_badan : item.pph_badan,
          pph_perorangan : item.pph_perorangan
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getUserById({ commit }, id) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.USER}/${id}`); 
      if (response.data.status_code === 200) { 
        return response.data.data;
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  setSelectedPoEvent({ commit }, poEvent) {
    commit('SET_PO_EVENT', poEvent);
  }

}; 

const getters = { 
  getPoEvents(state) { 
    return state.po_events; 
  }, 
  getPoEvent(state) { 
    return state.po_event; 
  }, 
  getTotalPoEvents(state) { 
    return state.total; 
  }, 
  isLoading(state) { 
    return state.loading; 
  }, 
  getError(state) { 
    return state.error; 
  } 
}; 

export default { 
  namespaced: true, 
  state, 
  mutations, 
  actions, 
  getters 
};
